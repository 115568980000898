import React, { useContext } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import styled from 'styled-components';

import { GlobalContext } from '../components/GlobalContext';

import bp from '../assets/js/breakpoints';
import {
  isBrowser,
  getContrastingColor,
  prefersReducedMotion,
} from '../assets/js/utilities';

import Image from './Image';
import useTrackLength from '../hooks/useTrackLength';

const ButtonIcon = ({ contrastingColor, reverseContrastingColor }) => {
  return (
    <IconWrapper
      contrastingColor={contrastingColor}
      reverseContrastingColor={reverseContrastingColor}
      className="play-icon"
    >
      <svg
        width="10"
        height="14"
        viewBox="0 0 8 14"
        fill={contrastingColor}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 12.0793V1.92066C0 1.11163 0.910686 0.63748 1.57346 1.10142L8.82967 6.18077C9.39839 6.57887 9.39838 7.42113 8.82967 7.81923L1.57346 12.8986C0.910685 13.3625 0 12.8884 0 12.0793Z" />
      </svg>
    </IconWrapper>
  );
};

const Button = ({
  spotify_id,
  trackLength,
  contrastingColor,
  reverseContrastingColor,
  story_color,
}) => {
  const { setStoryColor, setCurrentSpotifyEpisode } = useContext(GlobalContext);

  function handleClick() {
    setCurrentSpotifyEpisode(spotify_id);
    setStoryColor(story_color);
  }

  return (
    <ButtonWrapper
      contrastingColor={contrastingColor}
      reverseContrastingColor={reverseContrastingColor}
      onClick={handleClick}
    >
      <ButtonIcon
        contrastingColor={contrastingColor}
        reverseContrastingColor={reverseContrastingColor}
      />
      <div className="button-text--mobile">
        {trackLength ? (
          <p>
            {`${trackLength.hoursPadded}:${trackLength.minutes}:${trackLength.seconds}`}
          </p>
        ) : (
          <p>Listen Now</p>
        )}
      </div>

      <div className="button-text--desktop">
        <p>Listen Now</p>
      </div>
    </ButtonWrapper>
  );
};

const StoryCard = ({ as, story }) => {
  const { url, full_firstpub_date } = story.node;
  const {
    story_number,
    story_color,
    title,
    spotify_id,
    featured_image,
    full_custompub_date,
  } = story.node.data;

  const { pageBgColor, setPageBgColor } = useContext(GlobalContext);

  const isMobile = isBrowser && window.innerWidth < 750;

  // Get Track length from Spotify API
  const trackLength = useTrackLength(spotify_id);

  // Get contrasting colors based on the background color
  const contrastingColor = getContrastingColor(pageBgColor);
  const reverseContrastingColor = getContrastingColor(contrastingColor);

  return (
    <Wrapper
      onMouseEnter={() => {
        if (isMobile || prefersReducedMotion) return;
        setPageBgColor(story_color);
      }}
      onMouseLeave={() => {
        if (isMobile || prefersReducedMotion) return;
        setPageBgColor('var(--color-snow)');
      }}
      className="story-card"
      as={as}
    >
      <StoryNumber>Nº {story_number}</StoryNumber>

      <ImageWrapper>
        <Image image={featured_image} aspectRatio={1 / 1} />

        <StoryNumber>Nº {story_number}</StoryNumber>
      </ImageWrapper>

      <Content contrastingColor={contrastingColor}>
        <StoryDetails
          story_color={story_color}
          contrastingColor={contrastingColor}
        >
          <StoryInfo to={url}>
            <h3>{title}</h3>

            <div>
              <p className="story-date">
                {full_custompub_date ? full_custompub_date : full_firstpub_date}
              </p>
              {trackLength && (
                <p className="story-length">
                  {trackLength.hours !== 0 && trackLength.hours}{' '}
                  {trackLength.hours === 0
                    ? ''
                    : trackLength.hours === 1
                    ? 'Hour'
                    : 'Hours'}{' '}
                  {trackLength.minutes}{' '}
                  {trackLength.minutes === '01' ? 'Minute' : 'Minutes'}
                </p>
              )}
            </div>
          </StoryInfo>

          <Button
            story_color={story_color}
            spotify_id={spotify_id}
            contrastingColor={contrastingColor}
            reverseContrastingColor={reverseContrastingColor}
            trackLength={trackLength}
          />
        </StoryDetails>
      </Content>
    </Wrapper>
  );
};

export default StoryCard;

/**
 * Styled Components
 *
 */
const Content = styled.div`
  width: 100%;
  height: 100%;
`;

const StoryNumber = styled.div`
  display: flex;
  font-size: clamp(4.4rem, 1.174rem + 6.88vw, 8.4rem);
  color: var(--color-navy);
  font-weight: var(--font-weight-bold);
  line-height: 1;
  letter-spacing: -0.05em;

  @media (${bp.min.sm}) {
    display: none;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;

  img {
    transition: all 350ms var(--ease-cubic-in-out);
  }

  ${StoryNumber} {
    display: none;

    @media (${bp.min.sm}) {
      position: absolute;
      left: var(--space-s);
      bottom: 0;
      font-size: clamp(4.4rem, 1.174rem + 6.88vw, 8.4rem);
      color: var(--color-snow);
      font-weight: var(--font-weight-bold);
      line-height: 0.99;
      letter-spacing: -0.05em;
      display: flex;
      z-index: 10;
    }
  }
`;

const Wrapper = styled.div`
  position: relative;

  &:nth-of-type(odd):not(:nth-of-type(1)) {
    margin-top: -4.8rem;
  }

  &:nth-of-type(even) {
    margin-top: 9.6rem;
  }

  @media (${bp.min.sm}) {
    display: flex;
    flex-direction: column;

    &:nth-of-type(odd):not(:nth-of-type(1)) {
      margin-top: 0;
    }

    &:nth-of-type(even) {
      margin-top: 0;
    }
  }
`;

const StoryDetails = styled.div`
  padding: var(--space-xs) 0;
  border-bottom: 0.1rem solid var(--color-navy);

  @media (${bp.min.sm}) {
    padding: var(--space-s) 0;
    border-bottom: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  h3,
  .story-date,
  .story-length {
    font-size: 1.6rem;
    color: ${props => props.contrastingColor};
    font-weight: var(--font-weight-bold);
    line-height: 1.2;
    transition: color 250ms var(--ease-cubic-in-out);
  }

  .button-text--desktop {
    transition: color 250ms var(--ease-cubic-in-out);
    color: ${props => props.contrastingColor};
  }
`;

const StoryInfo = styled(GatsbyLink)`
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: auto;

  @media (${bp.min.sm}) {
    flex-direction: row;
    justify-content: space-between;
  }

  &:hover {
    color: currentColor;
  }

  @media (${bp.min.sm}) {
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 6rem;
      z-index: 100;
    }
  }

  h3,
  div {
    @media (${bp.min.sm}) {
      width: 50%;
    }
  }

  .story-date {
    @media (${bp.max.sm}) {
      font-size: 1.2rem;
      letter-spacing: 0.04rem;
      margin-bottom: var(--space-xxxs);
    }
  }

  .story-length {
    display: none;

    @media (${bp.min.sm}) {
      display: block;
    }
  }

  div {
    @media (${bp.min.sm}) {
      text-align: right;
    }
  }
`;

const IconWrapper = styled.div`
  --size: 2.8rem;

  background-color: ${props => props.contrastingColor};
  border-radius: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--size);
  height: var(--size);
  margin-right: var(--space-xxs);
  will-change: transform;
  transition: all 250ms var(--ease-cubic-in-out);

  @media (${bp.min.sm}) {
    --size: 2rem;

    background-color: ${props => props.reverseContrastingColor};
    border: 0.1rem solid ${props => props.contrastingColor};
  }

  svg {
    overflow: visible;
    width: 0.8rem;
    height: auto;
    fill: ${props => props.reverseContrastingColor};
    transition: all 250ms var(--ease-cubic-in-out);

    @media (${bp.min.sm}) {
      width: 0.6rem;
      fill: ${props => props.contrastingColor};
    }
  }
`;

const ButtonWrapper = styled.button`
  color: ${props => props.contrastingColor};
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  line-height: 1;
  font-weight: var(--font-weight-bold);
  letter-spacing: 0.04rem;
  text-transform: uppercase;
  margin-top: var(--space-xs);
  transition: all 250ms var(--ease-cubic-in-out);

  &:hover {
    color: ${props => props.contrastingColor} !important;

    ${IconWrapper} {
      transform: scale(1.15);
    }
  }

  .button-text--mobile {
    color: var(--color-navy);

    @media (${bp.min.sm}) {
      display: none;
    }
  }

  .button-text--desktop {
    display: none;

    @media (${bp.min.sm}) {
      display: flex;
    }
  }
`;
