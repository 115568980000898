import React, { useContext } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import styled from 'styled-components';

import { GlobalContext } from '../components/GlobalContext';

import bp from '../assets/js/breakpoints';

import Link from './Link';
import Image from './Image';
import useTrackLength from '../hooks/useTrackLength';

const ListItem = ({ story }) => {
  const { url, short_firstpub_date } = story.node;
  const {
    story_number,
    story_color,
    title,
    spotify_id,
    featured_image,
    short_custompub_date,
  } = story.node.data;

  const { setStoryColor, setCurrentSpotifyEpisode } = useContext(GlobalContext);

  // Get Track length from Spotify API
  const trackLength = useTrackLength(spotify_id);

  function handleClick() {
    setCurrentSpotifyEpisode(spotify_id);
    setStoryColor(story_color);
  }

  return (
    <ListItemWrapper>
      <StoryInfo>
        <Link url={{ url: url, link_type: 'Document' }}>
          <div className="story__number">Nº {story_number}</div>
          <div className="story__title">{title}</div>

          <div className="story__date">
            {short_custompub_date ? short_custompub_date : short_firstpub_date}
          </div>
          <div className="story__length">
            {trackLength && (
              <p>
                {`${trackLength.hoursPadded}:${trackLength.minutes}:${trackLength.seconds}`}
              </p>
            )}
          </div>
        </Link>

        <PlayButton onClick={handleClick}>
          <svg
            width="10"
            height="14"
            viewBox="0 0 8 14"
            fill="var(--color-navy)"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Play</title>
            <path d="M0 12.0793V1.92066C0 1.11163 0.910686 0.63748 1.57346 1.10142L8.82967 6.18077C9.39839 6.57887 9.39838 7.42113 8.82967 7.81923L1.57346 12.8986C0.910685 13.3625 0 12.8884 0 12.0793Z" />
          </svg>
        </PlayButton>
      </StoryInfo>

      <ImageWrapper to={url}>
        <Image image={featured_image} />
      </ImageWrapper>
    </ListItemWrapper>
  );
};

const StoriesListSmall = ({ stories }) => {
  return (
    <Wrapper>
      {stories.map((story, i) => (
        <ListItem story={story} key={i} />
      ))}
    </Wrapper>
  );
};

export default StoriesListSmall;

/**
 * Styled Components
 *
 */
const Wrapper = styled.ul`
  position: relative;
  padding: var(--space-xxs) 0 0.4rem;
  z-index: 1;

  @media (${bp.min.sm}) {
    padding: var(--space-l) 0 8rem;
  }
`;

const StoryInfo = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  font-size: clamp(1.8rem, 1.75vw, 2.2rem);
  line-height: 1.1;
  font-weight: var(--font-weight-bold);
  letter-spacing: -0.1rem;
  padding: var(--space-s) 0;
  border-bottom: 0.1rem solid var(--color-navy);
  transition: background-color 150ms var(--ease-cubic-in-out);

  @media (${bp.min.sm}) {
    height: 5.6rem;
    display: grid;
    grid-gap: 2.4rem;
    padding: 0 var(--space-xs);
    grid-template-columns: 1fr 2.8rem;
  }

  .link-tag {
    height: 100%;
    margin-right: auto;
    padding-right: var(--space-m);

    @media (${bp.min.sm}) {
      display: grid;
      grid-template-columns: 6rem 1fr 8rem 8rem;
      grid-gap: 2.4rem;
      align-items: center;
      padding-right: 0;
      margin-right: 0;
    }

    @media (${bp.min.lg}) {
      grid-template-columns: 8rem 1fr 10rem 10rem;
    }
  }

  .story__number {
    @media (${bp.max.sm}) {
      font-size: 1.2rem;
      margin-bottom: var(--space-xxxs);
    }
  }

  .story__title {
    @media (${bp.max.sm}) {
      font-size: 2rem;
      font-weight: var(--font-weight-normal);
    }
  }

  .story__date,
  .story__length {
    display: none;

    @media (${bp.min.sm}) {
      display: block;
    }
  }
`;

const PlayButton = styled.button`
  --size: 3.6rem;

  width: var(--size);
  height: var(--size);
  border-radius: var(--size);
  background-color: var(--color-snow);
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  cursor: pointer;
  border: 0.1rem solid var(--color-navy);
  transition: opacity 150ms var(--ease-cubic-in-out);

  @media (${bp.min.sm}) {
    --size: 2.8rem;

    opacity: 0;
    border: 0;
  }

  &,
  svg {
    transition: all 150ms var(--ease-cubic-in-out);
  }

  @media (hover: hover) {
    &:hover {
      background-color: var(--color-navy);

      svg {
        fill: var(--color-snow);
      }
    }
  }

  svg {
    position: relative;
    left: 0.05rem;
    width: 1.2rem;
    height: auto;

    @media (${bp.min.sm}) {
      width: 1rem;
    }
  }
`;

const ImageWrapper = styled(GatsbyLink)`
  display: none;
  height: 5.6rem;
  opacity: 0;
  transition: opacity 150ms var(--ease-cubic-in-out);
  grid-column-start: 3;

  @media (${bp.min.md}) {
    display: block;
  }

  img {
    max-width: 100%;
  }
`;

const ListItemWrapper = styled.li`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-between;

  @media (${bp.min.md}) {
    grid-template-columns: 1fr 4.8rem 18rem;
  }

  &:hover {
    @media (${bp.min.sm}) {
      &,
      a {
        color: var(--color-snow);
      }

      ${StoryInfo} {
        background-color: var(--color-great-blue);
      }

      ${ImageWrapper} {
        opacity: 1;
      }

      ${PlayButton} {
        opacity: 1;
      }
    }
  }

  &,
  a {
    transition: all 150ms var(--ease-cubic-in-out);
  }
`;
