import React, { useContext } from 'react';
import styled from 'styled-components';

import { GlobalContext } from '../components/GlobalContext';

import bp from '../assets/js/breakpoints';
import useTrackLength from '../hooks/useTrackLength';

import Link from './Link';
import Image from './Image';

const ButtonIcon = () => {
  return (
    <IconWrapper className="play-icon">
      <svg
        width="10"
        height="14"
        viewBox="0 0 8 14"
        fill="var(--color-navy)"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 12.0793V1.92066C0 1.11163 0.910686 0.63748 1.57346 1.10142L8.82967 6.18077C9.39839 6.57887 9.39838 7.42113 8.82967 7.81923L1.57346 12.8986C0.910685 13.3625 0 12.8884 0 12.0793Z" />
      </svg>
    </IconWrapper>
  );
};

const Button = ({ spotify_id, story_color }) => {
  const { setCurrentSpotifyEpisode, setStoryColor } = useContext(GlobalContext);

  function handleClick() {
    setCurrentSpotifyEpisode(spotify_id);
    setStoryColor(story_color);
  }

  return (
    <ButtonWrapper onClick={handleClick}>
      <ButtonIcon />

      <p>Listen</p>
    </ButtonWrapper>
  );
};

const ListItem = ({ story }) => {
  const { url, full_firstpub_date } = story.node;
  const {
    story_number,
    story_color,
    title,
    spotify_id,
    featured_image,
    full_custompub_date,
  } = story.node.data;

  // Get Track length from Spotify API
  const trackLength = useTrackLength(spotify_id);

  return (
    <ListItemWrapper>
      <StoryLeft>
        <Link url={{ url: url, link_type: 'Document' }}>
          <StoryInfo>
            <div className="story__number">Nº {story_number}</div>
            <div className="story__date">
              {full_custompub_date ? full_custompub_date : full_firstpub_date}
            </div>
          </StoryInfo>

          <Title>{title}</Title>
        </Link>
      </StoryLeft>

      <StoryRight>
        <TrackInfo>
          <Button
            story_color={story_color}
            spotify_id={spotify_id}
            trackLength={trackLength}
          />

          <div className="story__length">
            {trackLength && (
              <p>
                {`${trackLength.hoursPadded}:${trackLength.minutes}:${trackLength.seconds}`}
              </p>
            )}
          </div>
        </TrackInfo>

        <Image image={featured_image} />
      </StoryRight>
    </ListItemWrapper>
  );
};

const StoriesListLarge = ({ stories }) => {
  return (
    <Wrapper>
      {stories.map((story, i) => (
        <ListItem story={story} key={i} />
      ))}
    </Wrapper>
  );
};

export default StoriesListLarge;

/**
 * Styled Components
 *
 */
const Wrapper = styled.ul`
  padding: var(--space-xxs) 0 0.4rem;

  @media (${bp.min.sm}) {
    padding: var(--space-l) 0 0;
  }
`;

const ListItemWrapper = styled.li`
  display: grid;
  grid-template-columns: 1fr 18rem;
  grid-gap: var(--space-l);
  justify-content: space-between;
  border-bottom: 0.1rem solid var(--color-navy);
  padding: var(--space-xs) 0 var(--space-l);

  &:last-of-type {
    border-bottom: 0;
  }
`;

const Title = styled.h2`
  font-size: clamp(4.4rem, 6.5vw, 8.4rem);
  color: var(--color-navy);
  font-weight: var(--font-weight-bold);
  line-height: 1;
  letter-spacing: -0.05em;
  max-width: 20ch;
  transition: all 150ms var(--ease-cubic-in-out);
`;

const StoryLeft = styled.div`
  a {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: fit-content;
    height: 100%;

    &:hover {
      color: var(--color-navy);

      ${Title} {
        color: var(--color-great-blue);
      }
    }
  }
`;

const StoryRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StoryInfo = styled.div`
  display: grid;
  grid-template-columns: 12rem 12rem;
  align-items: center;
  margin-bottom: var(--space-l);

  .story__number {
    font-size: clamp(1.8rem, 1.75vw, 2.2rem);
    line-height: 1.1;
    font-weight: var(--font-weight-bold);
    letter-spacing: -0.1rem;
  }

  .story__date {
    font-size: 1.2rem;
    line-height: 1;
    font-weight: var(--font-weight-bold);
    letter-spacing: 0.04rem;
    text-transform: uppercase;
  }
`;

const TrackInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--space-m);

  .story__length {
    font-size: 1.2rem;
    line-height: 1;
    font-weight: var(--font-weight-bold);
    letter-spacing: 0.04rem;
    text-transform: uppercase;
  }
`;

const IconWrapper = styled.div`
  --size: 2rem;

  background-color: transparent;
  border-radius: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--size);
  height: var(--size);
  margin-right: var(--space-xxs);
  will-change: transform;
  border: 0.1rem solid var(--color-navy);
  transition: all 250ms var(--ease-cubic-in-out);

  svg {
    overflow: visible;
    width: 0.6rem;
    height: auto;
    fill: var(--color-navy);
    transition: all 250ms var(--ease-cubic-in-out);
  }
`;

const ButtonWrapper = styled.button`
  color: var(--color-navy);
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  line-height: 1;
  font-weight: var(--font-weight-bold);
  letter-spacing: 0.04rem;
  text-transform: uppercase;
  transition: all 250ms var(--ease-cubic-in-out);

  &:hover {
    ${IconWrapper} {
      transform: scale(1.15);
    }
  }
`;
