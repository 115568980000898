import React, { useContext } from 'react';
import styled from 'styled-components';

import { GlobalContext } from '../components/GlobalContext';

import bp from '../assets/js/breakpoints';
import { getContrastingColor } from '../assets/js/utilities';

import StoriesGrid from './StoriesGrid';
import StoriesListSmall from './StoriesListSmall';
import StoriesListLarge from './StoriesListLarge';

const LayoutButton = ({
  icon,
  contrastingColor,
  storiesPageLayout,
  setStoriesPageLayout,
}) => {
  return (
    <ButtonWrapper
      onClick={() => setStoriesPageLayout(icon)}
      isActive={storiesPageLayout === icon}
      className={icon === 'list-large' ? 'list-large' : null}
    >
      {icon === 'grid' && (
        <>
          <span className="sr-only">Grid View</span>

          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill={contrastingColor}
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Grid Icon</title>
            <path d="M14.4 24L24 24L24 14.4L14.4 14.4L14.4 24Z" />
            <path d="M14.4 9.6L24 9.6L24 0L14.4 -8.39259e-07L14.4 9.6Z" />
            <path d="M9.6 9.6L1.25889e-06 9.6L2.09815e-06 -2.09815e-06L9.6 -1.25889e-06L9.6 9.6Z" />
            <path d="M0 24L9.6 24L9.6 14.4L8.39259e-07 14.4L0 24Z" />
          </svg>
        </>
      )}

      {icon === 'list-large' && (
        <>
          <span className="sr-only">Large List View</span>

          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill={contrastingColor}
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Large List Icon</title>
            <path d="M4.8 0H0V4.8H4.8V0Z" />
            <path d="M0 14.4V9.6H4.8V14.4H0Z" />
            <path d="M0 19.2V24H4.8V19.2H0Z" />
            <path d="M9.6 19.2V24H24V19.2H9.6Z" />
            <path d="M24 14.4V9.6H9.6V14.4H24Z" />
            <path d="M24 0V4.8H9.6V0H24Z" />
          </svg>
        </>
      )}

      {icon === 'list-small' && (
        <>
          <span className="sr-only">Small List View</span>

          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill={contrastingColor}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 0H24V1.48076H0V0Z" />
            <path d="M0 5.62979H24V7.11055H0V5.62979Z" />
            <path d="M0 11.2596H24V12.7404H0V11.2596Z" />
            <path d="M0 16.8894H24V18.3702H0V16.8894Z" />
            <path d="M0 22.5192H24V24H0V22.5192Z" />
          </svg>
        </>
      )}
    </ButtonWrapper>
  );
};

const Stories = ({
  newStoriesHeader,
  storiesGridHeader,
  stories,
  showLatestStory,
}) => {
  const { storiesPageLayout, setStoriesPageLayout, pageBgColor } =
    useContext(GlobalContext);

  // Get contrasting colors based on the background color
  const contrastingColor = getContrastingColor(pageBgColor);

  return (
    <Wrapper>
      {showLatestStory && (
        <Title contrastingColor={contrastingColor}>{newStoriesHeader}</Title>
      )}

      <StoriesLayoutPicker contrastingColor={contrastingColor}>
        <h3 className="stories-layout-title--desktop">{storiesGridHeader}</h3>
        <h3 className="stories-layout-title--mobile">
          {storiesPageLayout === 'grid' ? 'Grid' : 'List'}
        </h3>

        <Buttons>
          <LayoutButton
            storiesPageLayout={storiesPageLayout}
            setStoriesPageLayout={setStoriesPageLayout}
            contrastingColor={contrastingColor}
            icon="grid"
          />
          <LayoutButton
            storiesPageLayout={storiesPageLayout}
            setStoriesPageLayout={setStoriesPageLayout}
            contrastingColor={contrastingColor}
            icon="list-large"
          />
          <LayoutButton
            storiesPageLayout={storiesPageLayout}
            setStoriesPageLayout={setStoriesPageLayout}
            contrastingColor={contrastingColor}
            icon="list-small"
          />
        </Buttons>
      </StoriesLayoutPicker>

      <div style={{ display: storiesPageLayout === 'grid' ? 'block' : 'none' }}>
        <StoriesGrid stories={stories} />
      </div>

      <div
        style={{
          display: storiesPageLayout === 'list-large' ? 'block' : 'none',
        }}
      >
        <StoriesListLarge stories={stories} />
      </div>

      <div
        style={{
          display: storiesPageLayout === 'list-small' ? 'block' : 'none',
        }}
      >
        <StoriesListSmall stories={stories} />
      </div>
    </Wrapper>
  );
};

export default Stories;

/**
 * Styled Components
 *
 */
const Wrapper = styled.section`
  margin-top: 8rem;
  border-bottom: 0.1rem solid var(--color-navy);
`;

const Title = styled.h2`
  font-size: clamp(3.6rem, 10vw, 9rem);
  padding: 0 var(--space-m);
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: -0.1rem;
  text-align: center;
  margin-bottom: var(--space-xl);
  color: ${props => props.contrastingColor};
  transition: color 300ms var(--ease-cubic-in-out);

  @media (${bp.min.sm}) {
    margin-bottom: 8rem;
  }
`;

const StoriesLayoutPicker = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--space-s) 0;
  border-top: 0.1rem solid ${props => props.contrastingColor};
  border-bottom: 0.1rem solid ${props => props.contrastingColor};
  transition: border-color 300ms var(--ease-cubic-in-out);

  @media (${bp.min.sm}) {
    align-items: baseline;
    padding-bottom: var(--space-l);
    border-top: 0;
  }

  &:after {
    content: '';
    width: 100%;
    border-bottom: 0.1rem solid ${props => props.contrastingColor};
    position: absolute;
    bottom: 0.4rem;
    transition: border-color 300ms var(--ease-cubic-in-out);
  }

  h3 {
    font-weight: var(--font-weight-bold);
    color: ${props => props.contrastingColor};
    transition: color 300ms var(--ease-cubic-in-out);

    &.stories-layout-title--mobile {
      font-size: 2.2.rem;
      text-transform: uppercase;
      letter-spacing: 0.01rem;
      line-height: 1;

      @media (${bp.min.sm}) {
        display: none;
      }
    }

    &.stories-layout-title--desktop {
      display: none;
      font-size: clamp(4.4rem, 3.788rem + 3.06vw, 7rem);
      line-height: 0.85;
      letter-spacing: -0.4rem;

      @media (${bp.min.sm}) {
        display: flex;
      }
    }
  }
`;

const Buttons = styled.div`
  display: flex;
`;

const ButtonWrapper = styled.button`
  --size: 3.2rem;

  cursor: pointer;
  padding: var(--space-xxxs);
  width: var(--size);
  height: var(--size);

  & + & {
    margin-left: var(--space-xs);
  }

  &.list-large {
    display: none;

    @media (${bp.min.sm}) {
      display: block;
    }
  }

  svg {
    fill: ${props => props.isActive && 'var(--color-great-blue)'};
    transition: all 150ms var(--ease-cubic-in-out);
  }

  &:hover {
    svg {
      fill: var(--color-great-blue);
    }
  }
`;
