import React, { useContext } from 'react';
import styled from 'styled-components';

import { GlobalContext } from '../components/GlobalContext';

import bp from '../assets/js/breakpoints';
import { getContrastingColor } from '../assets/js/utilities';

import StoryCard from './StoryCard';

const StoriesGrid = ({ stories }) => {
  const { pageBgColor } = useContext(GlobalContext);

  // Get contrasting colors based on the background color
  const contrastingColor = getContrastingColor(pageBgColor);
  const reverseContrastingColor = getContrastingColor(contrastingColor);

  return (
    <Wrapper
      pageBgColor={pageBgColor}
      contrastingColor={contrastingColor}
      reverseContrastingColor={reverseContrastingColor}
    >
      {stories.map((story, i) => (
        <StoryCard key={i} story={story} as="li" />
      ))}
    </Wrapper>
  );
};

export default StoriesGrid;

/**
 * Styled Components
 *
 */
const Wrapper = styled.ul`
  display: grid;
  grid-gap: 0 var(--space-s);
  grid-template-columns: repeat(2, 1fr);
  padding: var(--space-l) 0 4.8rem;

  @media (${bp.min.sm}) {
    padding: 4.8rem 0;
    pointer-events: none;
    grid-gap: 4.8rem var(--space-m);

    &:hover,
    &:focus-within {
      > * {
        filter: saturate(0);
        opacity: 0.5;

        .play-icon {
          background-color: transparent;
        }
      }
    }

    .story-card {
      pointer-events: auto;
      transition: all 300ms var(--ease-cubic-in-out);

      &:hover,
      &:focus-within {
        filter: saturate(1);
        opacity: 1;

        .play-icon {
          background-color: ${props => props.contrastingColor};

          svg {
            fill: ${props => props.reverseContrastingColor};
          }
        }
      }
    }
  }

  @media (${bp.min.md}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
